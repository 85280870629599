<template>
  <div>
    <div style="margin-bottom: 20px;">
      <el-button
        type="primary"
        @click="handleSave"
      >
        Save All
      </el-button>
    </div>
    <el-button
      type="success"
      @click="add"
      style="margin-bottom: 20px;"
    >
      Add
    </el-button>

    <el-table
      :data="cameraList"
    >
      <el-table-column prop="name" label="Name" min-width="150">
        <template slot-scope="{ row }">
          <el-input v-model="row.name" placeholder="Please enter"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Type" min-width="150">
        <template slot-scope="{ row }">
          <el-select v-model="row.type" placeholder="Please select">
            <el-option label="ordinary camera" value="normal"></el-option>
            <el-option label="ground camera" value="ground"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Path" min-width="150">
        <template slot-scope="{ row }">
          <el-input v-model="row.path" placeholder="Please enter"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Code" min-width="150">
        <template slot-scope="{ row }">
          <el-input v-model="row.code" placeholder="Please enter"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Camera Position" min-width="150">
        <template slot-scope="{ row }">
          <el-input v-model="row.heightLocation" placeholder="Please enter"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Special Camera" min-width="150">
        <template slot-scope="{ row }">
          <el-select v-model="row.specialNormal" placeholder="Please select">
            <el-option label="Yes" :value="true"></el-option>
            <el-option label="No" :value="false"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Action" min-width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="cameraList.splice(scope.$index, 1)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getCameras, addCamera } from '@/api/tower-manage'

export default {
  data() {
    return {
      cameraList: [],
      form: {}
    }
  },
  created() {
    getCameras({towerCode: this.$route.query.towerCode}).then((res) => {
      this.cameraList = res.result
    })
  },
  methods: {
    add() {
      this.cameraList.push({
        name: '',
        type: '',
        path: '',
        code: '',
        heightLocation: '',
        specialNormal: '',
        towerCode: this.$route.query.towerCode
      })
    },

    handleSave() {
      console.log('save', this.cameraList)
        addCamera(this.cameraList).then((res) => {
          this.$message.success('Successfully saved')
        }).catch(() => {})
    },
  }
}
</script>

<style lang="scss" scoped>
.list-view-table {
  ::v-deep .el-table__row .cell {
    height: 90px;
    line-height: 50px;
  }
}
</style>
