var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSave}},[_vm._v(" Save All ")])],1),_c('el-button',{staticStyle:{"margin-bottom":"20px"},attrs:{"type":"success"},on:{"click":_vm.add}},[_vm._v(" Add ")]),_c('el-table',{attrs:{"data":_vm.cameraList}},[_c('el-table-column',{attrs:{"prop":"name","label":"Name","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"Please enter"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Type","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"Please select"},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}},[_c('el-option',{attrs:{"label":"ordinary camera","value":"normal"}}),_c('el-option',{attrs:{"label":"ground camera","value":"ground"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Path","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"Please enter"},model:{value:(row.path),callback:function ($$v) {_vm.$set(row, "path", $$v)},expression:"row.path"}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Code","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"Please enter"},model:{value:(row.code),callback:function ($$v) {_vm.$set(row, "code", $$v)},expression:"row.code"}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Camera Position","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"Please enter"},model:{value:(row.heightLocation),callback:function ($$v) {_vm.$set(row, "heightLocation", $$v)},expression:"row.heightLocation"}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Special Camera","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"Please select"},model:{value:(row.specialNormal),callback:function ($$v) {_vm.$set(row, "specialNormal", $$v)},expression:"row.specialNormal"}},[_c('el-option',{attrs:{"label":"Yes","value":true}}),_c('el-option',{attrs:{"label":"No","value":false}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Action","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.cameraList.splice(scope.$index, 1)}}},[_vm._v(" Delete ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }